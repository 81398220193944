<template>
  <v-container fluid>
    <div class="time">
        <v-hover v-slot="{hover}">
        <v-btn fab outlined class="monitor_btn" alt="모니터링 현황판" href="/FarmMap">
          <img :src="clock" class="clock_icon">
        
          <v-expand-transition>
            <div v-if="hover" class="monitor_hover" transition="scale-transition">
              <div style="padding:5px; text-align:right;">PIGRO 농장</div>
            </div>
          </v-expand-transition>
        </v-btn>
      </v-hover>
      {{ currentTime() }}
    </div>

    <div class="info__container"> 
      <div v-for="(item, i) in items" :key="i">
        <div class="farm__container">
        <div class="farm_name">
          <img :src="farm" class="farm_icon">
          {{ item.farm.name }} 
        </div> 

          <div v-for="(buildings, b) in item.buildings" :key="b">
            <div class="building">
              <span class="building_name" >
                <img :src="building" class="building_icon">
                {{ buildings.building.name }} 
              </span>

            <div class="room__container">
              <div v-for="(room, r) in buildings.rooms" :key="r">
                <div class="roomCard"  @click="clickRoom(item, buildings, room)">
                  <div class="room_name" :class="{color_gray:room.checkTime == null }">
                    {{ room.room_name }}
                  </div>

                  <div class="value__container">
                    <div class="value">
                      <v-icon size="45px" color="#3C677A" class="value_icon">
                        mdi-thermometer
                      </v-icon>

                      <!-- <span class="value_title">온도</span> -->
                      <span class="value_number">
                        {{ room.temp }}
                      </span>
                      <span class="unit">℃</span>
                    </div>

                    <div class="value">
                      <img :src="hum" class="value_icon">

                      <!-- <span class="value_title">습도</span> -->
                      <span class="value_number">
                        {{ room.hum }}
                      </span>
                      <span class="unit">%</span>
                    </div>

                    <div class="value" v-if="room.co2 !== '-'">
                      <img :src="co2" class="value_icon">

                      <!-- <span class="value_title">이산화탄소</span> -->
                      <span class="value_number">
                        {{ room.co2 }}
                      </span>
                      <span class="unit">㏙</span>
                    </div>
                    
                    <div class="value" v-if="room.nh3 !== '-'">
                      <img :src="nh3" class="value_icon">

                      <!-- <span class="value_title">암모니아</span> -->
                      <span class="value_number" :class="{ color_red: room.nh3 >= 1, color_green: room.nh3 < 1  }">
                        {{ room.nh3 }}
                      </span>
                      <span class="unit">㏙</span>
                    </div>
                    
                    <div class="value" v-if="room.h2s !== '-'">
                      <img :src="h2s" class="value_icon">


                      <!-- <span class="value_title">황화수소</span> -->
                      <span class="value_number" :class="{ color_red: room.h2s >= 0.02,color_green: room.h2s < 0.02 }">
                        {{ room.h2s }}
                      </span>
                      <span class="unit">㏙</span>
                    </div>   
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
var setTime = 0;

export default{
  name: "AllMonitor",

  async created() {
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

  
    this.refreshList();
    },
  destroyed() {
    clearTimeout(setTime);
  },
  data() {
    return {
      clock: require("@/assets/monitoring/clock.svg"),
      building: require("@/assets/monitoring/building.svg"),
      farm: require("@/assets/monitoring/farm.svg"),
      hum: require("@/assets/monitoring/humidity.svg"),
      nh3: require("@/assets/monitoring/nh3.svg"),
      h2s: require("@/assets/monitoring/h2s.svg"),
      co2: require("@/assets/monitoring/co2.svg"),

      isreload: false,

      items: [ ],
    };
  },
  methods: {
    currentTime() {
      return dateUtil.format(new Date, "yyyy-MM-dd HH:mm:ss");
    },
    clickRoom(item, buildings, room){
      this.$router.push({
        name: "Barometer",
        params:{
          company:item.company,
          farm:item.farm,
          building:buildings.building,
          room:{code:room.room_cd, name:room.room_name + " "+item.farm.name},
        }
      });
    },
    refreshList() {
      this.$store.commit("resMessage","");
      this.loading = true;


        // console.log("리스트");
        Apis.monitoringAllRooms({
          stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
        } ,(res) => {  // 정상처리
          this.items = res.data;

          this.$route.path == "/monitoring/allmonitor" ? setTime = setTimeout( () =>  this.refreshList(), 60 * 1000) : "" ;

          // console.log('item',this.items);

          // console.log("path",this.$route.path);
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("monitoringAllRooms 호출 오류",err);

          // alert("monitoringSummary 호출 오류" + err);
          this.$router.push("/");
          this.loading = false;
        })
      } 
    },
};

</script>

<style lang="scss" scoped>
@import url('https://fonts.cdnfonts.com/css/ds-digital');
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    transform : rotate(0.04deg);
}
*{
  margin:0;
  padding:0;
  letter-spacing: -1px;
  font-family: 'GmarketSansMedium';
}
.time{
  font-family: 'DS-Digital';
  margin:10px;
  text-align:center;
  font-size:45px;
  color:#4c6852;
  text-shadow: 2px 1px 2px gray; 
}
.clock_icon{
  height:30px;
  animation: vibration 0.1s infinite;
  // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
}
@keyframes vibration {
  from {
    transform: rotate(3deg);
  }
  to {
    transform: rotate(-3deg);
  }
}
.monitor_btn{
  height:50px !important;
  width:50px !important;
  line-height:50px !important;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
  border:2px solid #3a4f3f !important;
  background-color: white !important;
}
.monitor_btn:hover{
  background-color: #cee1d6 !important;
}
.monitor_hover{
  position:fixed;
  top:70px;
  background-color: #8aba9f;
  color:white;
  border-radius: 10px;
}

.farm__container{
  padding:10px;
  margin:30px;
  background-image: linear-gradient(to top, #f0f2f5 0%, #f8f8f8 100%);
  border-radius: 30px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
}
.farm_name{
  text-align:center;
  padding:20px;
  font-size:3rem;
  color:#3B4A3F;
}
.building{
  background-color:white;
  margin:10px;
  border-radius: 20px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
}
.building_name{
  display:inline-block;
  margin-left:10px;
  margin-top:10px;
  font-size:1.5rem;
  color:#3A4F3F;
  border:2px solid #4c6852;
  padding:10px;
  border-radius: 20px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
}
.room__container{
  position: relative;
  left: 50% !important;
  transform: translateX(-45%);
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  border-radius: 15px;
  padding-bottom:10px;
}
.roomCard{
  display:flex;
  flex-direction: column;
  width: 280px;
  height: 350px;
  box-sizing: border-box;
  margin:10px 30px 30px 10px;
  border-radius: 20px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  cursor: pointer;
}
.room_name{
  width:100%;
  height:80px;
  line-height:80px;
  border-radius: 10px 10px 0 0;
  text-align:center;
  font-size:1.8rem;
  font-weight:500;
  background-image: linear-gradient(to top, #3a4f3f 0%, #9bab9f 100%);
  color:white;
}
.value__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:100%;
  align-items: center;
  font-size:1.35rem;
}
.value_icon{
  width:45px;
  height:45px;
  margin-right:30px;
}
.value{
  display:flex;
  align-items: center;
  margin-bottom: 5px;
}
.value_title{
  display:inline-block;
  width:10rem;
  font-size:1.7rem;
  color:#3A4F3F;
}
.value_number{
  display:inline-block;
  width:5rem;
  font-size:1.6rem;
  text-align:right;
  color:#3A4F3F;
}
.unit{
  font-size:1rem;
  margin-left:10px;
}

.color_gray{
  background-image: linear-gradient(to top, #b6b8ba 0%, #cecfd0 52%, #d1cece 100%);
  color:black;
}
.color_red{
  color:#99191a;
}
.color_green{
  color:#2a5298;
}
.farm_icon{
  width:50px;
  height:50px;
}
.building_icon{
  width:30px;
  height:30px;
}

@media screen and (max-width: 820px){
  .time{
    font-size:1rem;
  }
  .farm__container{
    padding:0;
    margin:8px;
  }
  .roomCard{
    width:250px;
    margin:10px;
  }
}
</style>
